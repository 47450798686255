import React, { useEffect, useContext } from 'react';
import AppContext from '@src/context'
import Image from '@components/utility/Image';
import { withArtDirection, getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import './ResourceBanner.scss';

import CloseBtn from '@images/resource-banner/close-btn.svg';

import CirclesPlus from '@images/resource-banner/circles-plus-signs.svg';
import CirclesPlusDesktop from '@images/resource-banner/circles-plus-signs-desktop.svg';

import CirclesPlus2 from '@images/resource-banner/circles-plus-signs2.svg';
import CirclesPlus2Desktop from '@images/resource-banner/circles-plus-signs2-desktop.svg';

const ResourceBanner = () => {
    const context = useContext(AppContext);
    const { setIsBannerVisible } = context;

    const bannerQuery = useStaticQuery(graphql`
        query {
            kidDesktop: file(relativePath: {eq: "resource-banner/kid-desktop.png"}) {
                childImageSharp {
                    gatsbyImageData(
                        layout: FULL_WIDTH
                        formats: [AUTO, WEBP, AVIF]
                        transformOptions: { fit: OUTSIDE }
                        placeholder: NONE
                    )
                }
            },
            kidMobile: file(relativePath: {eq: "resource-banner/kid-mobile.png"}) {
                childImageSharp {
                    gatsbyImageData(
                        layout: FULL_WIDTH
                        formats: [AUTO, WEBP, AVIF]
                        transformOptions: { fit: OUTSIDE }
                        placeholder: NONE
                    )
                }
            },
        }
    `);

    const bannerImg = withArtDirection(getImage(bannerQuery.kidDesktop), [
        {
            media: "(max-width: 1023px)",
            image: getImage(bannerQuery.kidMobile),
        },
    ]);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (window.location.href.indexOf('ed=1') > -1 && !window.location.href.includes("resourceBanner")) {
                setIsBannerVisible(false)
            }
        }
    }, []);

    return (
        <div className={`resource-banner ${!context.isBannerVisible ? 'hidden' : 'block'}`}>

            <button className='resource-banner__close-btn' aria-label='close' onClick={() => {
                setIsBannerVisible(!context.isBannerVisible)
            }}><img src={CloseBtn} alt="close" width={22} height={22} /></button>


            <div className='lg:flex lg:items-center lg:justify-between'>

                <div className='resource-banner-img-circle'>
                    <div className='resource-banner__circle'></div>

                    <Image addedClass="resource-banner__kids-image" imageData={bannerImg} />

                    <img src={CirclesPlus} alt="" width={98} height={70} className='absolute left-[244px] top-[176px] lg:hidden' />

                    <img src={CirclesPlusDesktop} alt="" width={180} height={106} className='max-w-none hidden absolute left-[182px] -top-[21px] lg:block lg:w-[154px] xl:left-[224px] desktop:w-[180px] desktop:left-[260px] desktop:-top-[24px] 3xl:left-[263px]' />
                </div>
                <div className="resource-banner__content">

                    <div className='resource-banner__text-content'>
                        <div>
                            <p className='resource-banner__heading'>LOOKING TO KEEP EMFLAZA&reg;&nbsp;<span className='lowercase'>(deflazacort)</span> IN&nbsp;YOUR SON'S TREATMENT&nbsp;PLAN?</p>

                        </div>

                        <div className='lg:pl-5 lg:-mt-2.5 min-[1200px]:pl-0'>
                            <p className='resource-banner__text'>Learn how to talk to your son's healthcare provider about staying on&nbsp;EMFLAZA.</p>

                            <a href="/understanding-dispense-as-written.pdf" className="resource-banner__link" target='_blank' rel='noopener noreferrer'>
                                <span className='text-center text-base leading-[0] lg:text-[17px]'>
                                    SEE THE FAQ
                                </span>
                            </a>
                        </div>
                    </div>

                    <img src={CirclesPlus2} alt="" width={134} height={117} className='absolute left-[235px] -bottom-[44px] z-[-1] lg:hidden' />
                    <img src={CirclesPlus2Desktop} alt="" width={184} height={191} className='hidden absolute -right-[18px] -bottom-[48px] z-[-1] lg:block lg:w-[118px] xl:w-[139px] xl:bottom-[-37px] desktop:w-[170px] desktop:-bottom-[45px] 3xl:w-[184px] 3xl:-bottom-[35px] 3xl:-right-5' />

                </div>
            </div>

            <div className='orange-horizontal-line'></div>
        </div>
    );
};



export default ResourceBanner;
