export const isMobile = (mobileMedia = '(min-width: 0px) and (max-width: 1023px)') => {
	if (typeof window !== 'undefined') {
		return window.matchMedia(mobileMedia).matches;
	}

	return true;
};

export const isEDMode = (query = 'ed=1') => {
	if (typeof window !== 'undefined') {
		return window.location.href.indexOf(query) >= 0;
	}

	return false;
};

export const bounds = (elem) => {
	const rect = elem.getBoundingClientRect();

	return {
		top: rect.top,
		left: rect.left,
		right: rect.right,
		bottom: rect.bottom,
		width: rect.width,
		height: rect.height,
	};
};

export const getWinSize = () => {
	if (typeof window !== 'undefined') {
		const w = window;
		const d = document;
		const e = d.documentElement;
		const g = d.getElementsByTagName('body')[0];
		const x = w.innerWidth || e.clientWidth || g.clientWidth;
		const y = w.innerHeight || e.clientHeight || g.clientHeight;

		return { width: x, height: y };
	}

	return { width: 0, height: 0 };
};

export const inViewport = (elem, entireBox = false) => {
	if (typeof window !== 'undefined') {
		const bds = bounds(elem);
		const doc = getWinSize();

		if (!entireBox) {
			return (
				bds.top >= 0 &&
				bds.left >= 0 &&
				bds.top <= doc.height &&
				bds.left <= doc.width
			);
		} else {
			const topLimit = bds.top - doc.height;
			const bottomLimit = bds.top + bds.height;
			const leftLimit = bds.left - doc.width;
			const rightLimit = bds.left + bds.width;

			if (topLimit <= 0 && bottomLimit >= 0 && leftLimit <= 0 && rightLimit >= 0) {
				return true;
			} else {
				return false;
			}
		}
	}

	return false;
};

export const debounce = (func, wait, immediate) => {
	let timeout = null;

	return function () {
		const context = this;
		const args = arguments;

		const later = () => {
			timeout = null;

			if (!immediate) {
				func.apply(context, args);
			}
		};

		const callNow = immediate && !timeout;

		clearTimeout(timeout);

		timeout = setTimeout(later, wait);

		if (callNow) {
			func.apply(context, args);
		}
	};
};

export const getOS = () => {
    let OSName = 'unknown-os';

    if (navigator.appVersion.includes('Win')) OSName = 'win';
    if (navigator.appVersion.includes('Mac')) OSName = 'mac';
    if (navigator.appVersion.includes('X11')) OSName = 'unix';
    if (navigator.appVersion.includes('iPhone')) OSName = 'ios';
    if (navigator.appVersion.includes('iPad')) OSName = 'ios';
    if (navigator.appVersion.includes('Linux')) OSName = 'linux';

    return OSName;
};

export const scrollToHash = (e) => {
	e.preventDefault();

	if (e.target?.hash) {
		const el = document.querySelector(e.target?.hash);
		window.history.pushState({}, '', e.target.hash);
		el.scrollIntoView({ behavior: "smooth" });
	}
};
