import { navigate } from "gatsby"

const navigateTo = (evt,path) => {
	// we hook into the gatsy routing api to navigate to internal pages
	evt.preventDefault();
	navigate(path);
}

const HeaderLinks = [
    {
        linkName: 'Understanding Corticosteroids',
        link: '/understanding-corticosteroids',
		clickFn: (evt) => navigateTo(evt, '/understanding-corticosteroids'),
		class: 'kf-menu-link-understanding-corticosteroids',
    },
    {
        linkName: 'About EMFLAZA',
        link: '/about-emflaza',
		clickFn: (evt) => navigateTo(evt, '/about-emflaza'),
		class: 'kf-menu-link-about-emflaza',

    },
    {
        linkName: 'Safety',
        link: '/safety',
		clickFn: (evt) => navigateTo(evt, '/safety'),
		class: 'kf-menu-link-safety',
    },
    {
		linkName: 'Dosing',
        link: '/dosing',
		clickFn: (evt) => navigateTo(evt, '/dosing'),
		class: 'kf-menu-link-dosing',
    },
    {
        linkName: 'Getting Started',
        link: '/getting-started',
		clickFn: (evt) => navigateTo(evt, '/ptc-cares-helps'),
		class: 'kf-menu-link-getting-started',
        subMenu: [
            {
                subName: '',
                subLink: '/ptc-cares-helps',
                clickFn: (evt) => navigateTo(evt, '/ptc-cares-helps'),
				class: 'kf-menu-link-ptc-cares-helps',
            },
            {
				subName: 'Caregiver Resources',
                subLink: '/caregiver-resources',
                clickFn: (evt) => navigateTo(evt, '/caregiver-resources'),
				class: 'kf-menu-link-caregiver-resources',
            },
			{
				subName: 'Doctor Discussion Guide',
                subLink: '/doctor-discussion-guide',
                clickFn: (evt) => navigateTo(evt, '/doctor-discussion-guide'),
				class: 'kf-menu-link-doctor-discussion-guide',
            },
            {
				subName: 'Video Library',
                subLink: '/video-library',
                clickFn: (evt) => navigateTo(evt, '/video-library'),
				class: 'kf-menu-link-video-library',
            }
        ]
    },
    {
        linkName: 'Información en Español',
        link: '/es',
        class: 'kf-menu-link-es',
    },
	{
        linkName: 'HCP Site',
        link: 'https://hcp.emflaza.com/',
		target: '_blank',
    },
	{
        linkName: 'Prescription Start Form',
        link: '/prescription-start-form.pdf',
		target: '_blank',
    },
];

export default HeaderLinks;
