import React, { useRef, useState, useEffect } from "react";
import {
	KfButton,
	inViewport,
	debounce,
	isEdMode,
} from "@klickinc/kf-react-components";
import Isi from "@components/content/isi/Isi";
import SpanishIsi from "@components/content/isi/SpanishIsi";
import "./styles.scss";

const StickyTray = ({pathname}) => {
	const isiRef = useRef();
	const footerRef = useRef();
	const [contentInView, setContentInView] = useState(false);
	const scrollToIsi = (e) => {
		const html = document.querySelector('html');
		const body = document.querySelector('body');
		html.setAttribute('style', 'overflow: auto')
		body.setAttribute('style', 'overflow: auto')

		isiRef.current = document.body.querySelector("#isi");
		if (isiRef.current) {
			isiRef.current.scrollIntoView({ behavior: "smooth" });
		}
	};

	const handleScroll = () => {
		isiRef.current = document.body.querySelector("#isi");
		footerRef.current = document.body.querySelector("footer");

		if (isiRef.current && footerRef.current) {
			if ("IntersectionObserver" in window) {
				const iObserver = new IntersectionObserver(
					(entries, observer) => {
						entries.forEach((entry) => {
							if (entry.isIntersecting || entry.boundingClientRect.y < 0) {
								// Keep ISI tray hidden if we've scrolled past the ISI copy
								setContentInView(true);
								iObserver.unobserve(entry.target);
							} else {
								setContentInView(false);
							}
						});
					}
				);

				iObserver.observe(isiRef.current);
			} else {
				const isiInView = inViewport(isiRef.current, true);
				const footerInView = inViewport(footerRef.current, true);

				if (isiInView || footerInView) {
					setContentInView(true);
				} else {
					setContentInView(false);
				}
			}
		}

		const windowScrollY = window.scrollY || window.pageYOffset;
		const isi = document.querySelector(".sticky-tray");

		if (windowScrollY > 100) {
			if (isi) isi.style.height = "5rem";
		} else {
			if (isi) isi.style.height = "";
		}
	};

	useEffect(() => {
		const scrollListener = debounce(handleScroll, 18);
		document.addEventListener("scroll", scrollListener);

		// run handleScroll at the very end so that StickyTray shows up on some low-height pages (Sitemap, 404, 500)
		setTimeout(() => {
			handleScroll();
		}, 0);
		return () => {
			document.removeEventListener("scroll", scrollListener);
		};
    },[])

	return (
		<>
			<div
				className={`sticky-tray ${
					contentInView || isEdMode() ? "hidden" : "block"
				}`}
			>
				<KfButton
					addedClass="container kf-button"
					clickFn={() => scrollToIsi()}
				>
					{ pathname === '/es' ?
						<>
							Información de seguridad importante
						</>
						:
						<>
							Important Safety Information and Indication
						</>
					}
				</KfButton>
				<div className="container pt-6 bg-white">
					{pathname === '/es' ?
						<SpanishIsi isFullIsiShown={contentInView} />
						:
						<Isi isFullIsiShown={contentInView} />
					}
				</div>
			</div>

			<div className="sticky-tray-ss">
				<KfButton
					addedClass="container kf-button"
					clickFn={() => scrollToIsi()}
				>
					{ pathname === '/es' ?
						<>
							Información de seguridad importante
						</>
						:
						<>
							Important Safety Information and Indication
						</>
					}
				</KfButton>
				<div className="container pt-6 bg-white">
					{pathname === '/es' ?
						<SpanishIsi isFullIsiShown={false} />
						:
						<Isi isFullIsiShown={false} />
					}
				</div>
			</div>
		</>
	);
};

export default StickyTray;
