import React from 'react';
import { AppProvider } from './src/context';
import Layout from './src/components/structure/Layout.jsx';
import GTM from '@utils/GTM'
// Style imports
import "@styles/main.scss"

export const onRouteUpdate = () => {
	GTM.reset();
	GTM.initPageView();
}

export const wrapRootElement = ({ element }) => <AppProvider>{element}</AppProvider>;
export const wrapPageElement = ({ element, props }) => <Layout {...props}>{element}</Layout>;

export const shouldUpdateScroll = ({
	routerProps: { location },
  }) => {
	const { pathname, state } = location;
	const routes = ['/doctor-discussion-guide']
	// scroll to top if it's not DDG page OR if it's DDG page, but without query params (clicked from nav menu, sitemap, etc.)
	if ((!routes.includes(pathname)) || (routes.includes(pathname) && !state?.useScenario)) {
		window.scrollTo(0, 0)
	}

	return false
}
