import React, { useContext } from "react";
import AppContext from "@src/context";
import Modal from "./Modal.jsx";

const ExitModal = () => {
	const ctx = useContext(AppContext);

	const closeModal = () => {
		ctx.setModalOpen(false);
		ctx.setExternalUrl(null);
		ctx.setModalType(null);
	};

	return (
		<Modal addedClass="modal_exit" closeModalFn={closeModal}>
			<div
				className="modal_exit__panel"
				role="dialog"
				aria-modal="true"
				aria-labelledby="exit-modal__title"
			>
				<p id="exit-modal__title" className="mb-4">
					You are about to leave the Emflaza website. We are not
					responsible for the accuracy or content of information
					contained on any third-party websites nor do we necessarily
					monitor or endorse such websites. If you would like to leave
					the Emflaza website and visit the third-party website please
					select Continue.
				</p>
				<p className="mb-4">
					Are you sure you would like to leave the Emflaza website and
					visit this third-party website?
				</p>
				<div className="flex flex-col sm:flex-row justify-between max-w-[90%] m-auto">
					<button
						className="link-btn link-btn--blue sm:max-w-[18.75rem] md:max-w-[16rem] link-hero-dark-blue"
						onClick={closeModal}
					>
						Cancel
					</button>
					<br />
					<a
						href={ctx.externalUrl}
						className="link-btn link-btn--blue-empty sm:max-w-[18.75rem] md:max-w-[16rem] link-hero-dark-blue"
						target="_blank"
						rel="noreferrer"
						onClick={closeModal}
					>
						Continue
					</a>
				</div>
			</div>
		</Modal>
	);
};

export default ExitModal;
