import React from 'react'

const SkipToContent = () => (
		<div className='skip-to-content text-center font-bold'>
			<a href="#content" className='w-0 h-0 block overflow-hidden focus:w-auto focus:h-auto focus:border-2 focus:border-emflaza-blue-600'>
				Skip to Content
			</a>
		</div>
    )

export default SkipToContent;
