import React from "react"
import Image from '@components/utility/Image';
import { Link, useStaticQuery, graphql } from "gatsby";
import { getImage } from 'gatsby-plugin-image';
import './styles.scss';

function Footer() {

	const query = useStaticQuery(graphql`
		query {
			footerLogoPtc: file(relativePath: {eq: "logos/footer-logo-ptc.png"}) {
				childImageSharp {
					gatsbyImageData
				}
			},
		}
	`);

	const image_footerLogoPtc = getImage(query.footerLogoPtc);
    return(
        <footer className="p-6 text-white bg-emflaza-blue-200" lang="en">
            <div className="md:container xl:pl-12 2xl:mx-auto">
                <ul>
                    <li><Link to='/' className='link link--white font-gotham font-bold'>Homepage</Link></li>
                    <li><Link to='/understanding-corticosteroids' className='link link--white font-gotham font-bold'>Understanding<br className="sm:hidden"/> Corticosteroids</Link></li>
                    <li><Link to='/about-emflaza' className='link link--white font-gotham font-bold'>About<br className="sm:hidden"/> EMFLAZA</Link></li>
                    <li><Link to='/safety' className='link link--white font-gotham font-bold'>Safety</Link></li>
                    <li><Link to='/dosing' className='link link--white font-gotham font-bold'>Dosing</Link></li>
                    <li><Link to='/ptc-cares-helps' className='link link--white font-gotham font-bold'>PTC <span className='italic'>Cares</span><sup>TM</sup></Link></li>
                    <li><Link to='/caregiver-resources' className='link link--white font-gotham font-bold'>Caregiver<br className="sm:hidden"/> Resources</Link></li>
                    <li className="footer--last-list-item-row1"><Link to="/doctor-discussion-guide" className='link link--white font-gotham font-bold'>Doctor Discussion Guide</Link></li>
                    <li><Link to='/video-library' className='link link--white font-gotham font-bold'>Video Library</Link></li>
                    <li><Link lang="es" to='/es' className='link link--white font-gotham font-bold'>Información<br className="sm:hidden"/> en Español</Link></li>
                    <li><Link to='/sign-up-for-updates' className='link link--white font-gotham font-bold'>Sign up for<br className="sm:hidden"/> Updates</Link></li>
                    <li><Link to='/contact-us' className='link link--white font-gotham font-bold'>Contact Us</Link></li>
                    <li><a className="kf-anchorlink link link--white font-gotham font-bold" href="https://www.ptcbio.com/terms-of-use" target="_blank" rel="noopener noreferrer">Terms of Use</a></li>
                    <li><a className="kf-anchorlink link link--white font-gotham font-bold" href="https://www.ptcbio.com/privacy-statement" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
                    <li className="footer--second-last-list-item"><Link to='/cookie-policy' className='link link--white font-gotham font-bold'>Do Not Sell or Share My Personal Information</Link></li>
                    <li className="footer--last-list-item"><Link to='/sitemap' className='link link--white font-gotham font-bold'>Sitemap</Link></li>
                </ul>
                <div className="mt-3 mb-5 md:flex md:mt-0 items-end">
                    <div className="text-xs font-gotham font-medium md:flex-1 md:pt-3">
                        <p>This site is intended for US audiences only.</p>
                        <p className="mt-3">
                            PTC <span className='italic'>Cares</span>™ is a trademark of PTC Therapeutics, Inc.
                            <br />
                            Emflaza<sup className='text-lg leading-3 top-0'>&reg;</sup> is a registered trademark of PTC&nbsp;Therapeutics, Inc.
                            <br/>
                            © 2023 PTC Therapeutics, Inc. All Rights Reserved.
                        </p>
                        <p>US-EMF-0299v6 10/23</p>
                    </div>
                    <div className="md:flex-1 md:text-right">
					<a className="kf-anchorlink inline-block mt-12 md:mt-0 z-0 relative" href="https://www.ptcbio.com/our-company/about" target="_blank" rel="noopener noreferrer">
						<span className="sr-only">PTC Therapeutics homepage</span>
						<span aria-hidden='true'>
							<Image imageData={image_footerLogoPtc} alt="PTC Therapeutics homepage" />
						</span>
					</a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
