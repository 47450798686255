import React from "react";
import Image from '@components/utility/Image';
import { getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import { KfAnchorLink } from "@klickinc/kf-react-components";
import { useLocation } from "@reach/router";
import './styles.scss';

const TranscriptISI = () => {
	const { pathname } = useLocation();

    const getJobCode = () => {
		switch (pathname) {
			case '/video-transcript/challenging-expectations':
				return 'US-EMF-0279'
			case '/video-transcript/making-the-grade':
				return 'US-EMF-0280'
			case '/video-transcript/here-now-kasners':
				return 'US-EMF-0376 06/22'
			case '/video-transcript/here-now-losapios':
				return 'US-EMF-0375 06/22'
			case '/video-transcript/he-is-manny':
				return 'US-EMF-0315 10/21'
			case '/video-transcript/making-a-switch':
				return 'US-EMF-0391 09/22'
			case '/video-transcript/haciendo-un-cambio':
				return 'US-EMF-0402 01/23'
			case '/video-transcript/efficacy-deep-dive':
				return 'US-EMF-0411 06/23'
			default:
				break;
		}
    }

	const query = useStaticQuery(graphql`
			query {
				sparkles: file(relativePath: {eq: "pages/video-transcript/transcript-sparkles.png"}) {
					childImageSharp {
						gatsbyImageData
					}
				},
				border: file(relativePath: {eq: "pages/video-transcript/transcript-border.png"}) {
					childImageSharp {
						gatsbyImageData
					}
				},
			}
	`);

	const image_sparkles = getImage(query.sparkles);
	const image_border = getImage(query.border);

	const includeISITitle = () => {
		return pathname.includes('here-now-kasners') || pathname.includes('here-now-losapios') || pathname.includes('/video-transcript/making-a-switch') || pathname.includes('/video-transcript/efficacy-deep-dive');
	}

	return (
        <>
            <div className="isi-copy-border w-full">
                {
                    pathname.includes('challenging-expectations') || pathname.includes('making-the-grade') ?
                    null
                    :
                    <div className="isi-copy-border--inner">
						<Image
							imageData={image_sparkles}
							alt=''
							loading='eager'
						/>
						<Image
							imageData={image_border}
							alt=''
							loading='eager'
						/>
                    </div>
                }
            </div>
            <div className="isi font-gotham">
                <div className="container">
					{
					(pathname.includes('/video-transcript/here-now-kasners') ||
					pathname.includes('/video-transcript/here-now-losapios') ||
					pathname.includes('/video-transcript/he-is-manny') ||
					pathname.includes('/video-transcript/making-a-switch') ||
					pathname.includes('/video-transcript/efficacy-deep-dive')) &&
						<div className="isi-copy">
							{includeISITitle() && <h2>INDICATION &amp; IMPORTANT SAFETY INFORMATION FOR EMFLAZA® (deflazacort)</h2>}
							{pathname.includes('he-is-manny') && <h2>SUMMARY OF INFORMATION FOR EMFLAZA®</h2>}
							<h3>What is EMFLAZA® (deflazacort) used for?</h3>
							<p>EMFLAZA is a prescription medicine used to treat Duchenne muscular dystrophy (DMD) in patients 2 years of age and older.</p>
							<h3>When should I not take EMFLAZA?</h3>
							<p>Do not use if you have had hypersensitivity, including allergic reactions, to deflazacort or any of the inactive ingredients.</p>
							<h3>What warnings should I know about EMFLAZA?</h3>
							<ul>
								<li>EMFLAZA can cause changes in endocrine function. Do not stop taking EMFLAZA, or change the amount you are taking, without first checking with your healthcare provider, as there may be a need for gradual dose reduction to decrease the risk of adrenal insufficiency and steroid “withdrawal syndrome”. Acute adrenal insufficiency can occur if corticosteroids are withdrawn abruptly, and can be fatal. A steroid “withdrawal syndrome,” seemingly unrelated to adrenocortical insufficiency, may also occur following abrupt discontinuance of corticosteroids. For patients already taking corticosteroids during times of stress, the dosage may need to be increased.</li>
								<li>There is an increased risk of infection when taking EMFLAZA. Tell the healthcare provider if the patient has had recent or ongoing infections or if they have recently received a vaccine. Medical advice should be sought immediately if the patient develops fever or other signs of infection. Patients and/or caregivers should be made aware that some infections can potentially be severe and fatal. Warn patients who are on corticosteroids to avoid exposure to chickenpox or measles and to alert their healthcare provider immediately if they are exposed.</li>
								<li>EMFLAZA can cause an increase in blood pressure and water retention. If this occurs, dietary salt restriction and potassium supplementation may be needed.</li>
								<li>There is an increased risk of developing a hole in the stomach or intestines in patients with certain stomach or intestine disorders when taking corticosteroids like EMFLAZA.</li>
								<li>EMFLAZA can cause severe behavioral and mood changes. Seek medical attention from the health care provider if any behavioral or mood changes develop.</li>
								<li>There is a risk of osteoporosis with prolonged use of EMFLAZA, which can lead to vertebral and long bone fractures.</li>
								<li>EMFLAZA may cause cataracts or glaucoma and a health care provider should monitor for these conditions if corticosteroid therapy is continued for more than 6 weeks.</li>
								<li>Immunizations should be up-to-date according to immunization guidelines prior to starting therapy with EMFLAZA. Live-attenuated or live vaccines should be administered at least 4 to 6 weeks prior to starting EMFLAZA. Live-attenuated or live vaccines should not be used in patients taking EMFLAZA.</li>
								<li>EMFLAZA can cause serious skin rashes. Seek medical attention at the first sign of a rash.</li>
								<li>Rare instances of anaphylaxis have occurred in patients receiving corticosteroid therapy, including EMFLAZA.</li>
							</ul>
							<h3>What should I tell my health care provider?</h3>
							<p>Tell the health care provider about all medical conditions, including if the patient:</p>
							<ul>
								<li>is pregnant or planning to become pregnant. EMFLAZA® (deflazacort) can harm your unborn baby.</li>
								<li>is breastfeeding or planning to breastfeed. EMFLAZA may appear in breastmilk and could affect a nursing child.</li>
							</ul>
							<p>Certain medications can cause an interaction with EMFLAZA. Tell your healthcare provider of all the medicines you are taking, including over-the-counter medicines (such as insulin, aspirin or other NSAIDS), dietary supplements, and herbal products. Alternate treatment, dosage adjustment, and/or special test(s) may be needed during the treatment.</p>
							<h3>What are the side effects of EMFLAZA?</h3>
							<p>The most common side effects of EMFLAZA include facial puffiness or Cushingoid appearance, weight increased, increased appetite, upper respiratory tract infection, cough, frequent daytime urination, unwanted hair growth, central obesity, and colds. These are not all of the possible side effects of EMFLAZA. Call your doctor for medical advice about side effects.</p>
							<p className="font-bold text-emflaza-blue-600">To report an adverse event, please call <KfAnchorLink url="tel:18665624620" target="_blank" rel="noreferrer" linkClass='underline'>1-866-562-4620</KfAnchorLink> or email at <KfAnchorLink url="mailto:usmedinfo@ptcbio.com" target="_blank" rel="noreferrer" linkClass="underline">usmedinfo@ptcbio.com.</KfAnchorLink> You may also report side effects to FDA at <KfAnchorLink url="tel:18003321088" target="_blank" rel="noreferrer" linkClass='underline'>1-800-FDA-1088</KfAnchorLink> or at <a href="https://www.fda.gov/medwatch" target='_blank' rel="noreferrer" className="underline">www.fda.gov/medwatch.</a></p>
							{(pathname.includes('here-now-kasners') || pathname.includes('here-now-losapios')) &&
								<p>PTC <span className='italic'>Cares</span>™ is a trademark of PTC Therapeutics, Inc. Emflaza<sup>®</sup> is a registered trademark of PTC Therapeutics, Inc. © 2022 PTC Therapeutics, Inc. All Rights Reserved. <br /> {getJobCode()}</p>
							}
							{(pathname.includes('he-is-manny') || pathname.includes('/video-transcript/making-a-switch')) &&
								<p>Emflaza<sup>®</sup> is a registered trademark of PTC Therapeutics, Inc. Trademarks, registered or otherwise, are property of their respective owner(s). © 2021 PTC Therapeutics, Inc. All Rights Reserved.<br /> {getJobCode()}</p>
							}

							{pathname.includes('efficacy-deep-dive') &&
								<p>
									Emflaza® is a registered trademark of PTC Therapeutics, Inc. <br />
									© 2023 PTC Therapeutics, Inc. All Rights Reserved. <br/>
									{getJobCode()}
								</p>
							}
						</div>
					}
					{(pathname.includes('challenging-expectations') || pathname.includes('making-the-grade')) &&
						<div className="isi-copy">
							<p>Emflaza<sup>®</sup> is a registered trademark of PTC Therapeutics, Inc. © 2020 PTC Therapeutics, Inc. All Rights Reserved. {getJobCode()}</p>
						</div>
					}
					{pathname.includes('haciendo-un-cambio') &&
						<div className="isi-copy">
							<h2>INDICACIÓN E INFORMACIÓN DE SEGURIDAD IMPORTANTE PARA EMFLAZA® (deflazacort)</h2>
							<h3>¿Para qué se utiliza EMFLAZA® (deflazacort)?</h3>
							<p>EMFLAZA es un medicamento con receta que se utiliza para tratar la distrofia muscular de Duchenne (DMD) en pacientes de 2 años o más.</p>
							<h3>¿Cuándo no debo tomar EMFLAZA?</h3>
							<p>No lo utilice si ha tenido hipersensibilidad, incluidas reacciones alérgicas, al deflazacort o a cualquiera de los componentes inactivos.</p>
							<h3>¿Qué advertencias debo saber sobre EMFLAZA?</h3>
							<ul className="bulleted-list">
								<li>EMFLAZA puede provocar cambios en la función endocrina. No deje de tomar EMFLAZA ni cambie la cantidad que está tomando sin consultar antes a su profesional de atención médica, ya que puede ser necesario reducir gradualmente la dosis para disminuir el riesgo de insuficiencia suprarrenal y “síndrome de abstinencia” de esteroides. Puede producirse insuficiencia suprarrenal aguda si se retiran los corticoesteroides de forma repentina, lo cual puede ser mortal. También puede producirse un “síndrome de abstinencia” de esteroides aparentemente no relacionado con la insuficiencia corticosuprarrenal tras la discontinuación repentina de los corticoesteroides. Es posible que sea necesario aumentar la dosis de los pacientes que ya estén tomando corticoesteroides en momentos de estrés.</li>
								<li>Existe un mayor riesgo de infección cuando se toma EMFLAZA. Informe al proveedor de atención médica si el paciente ha tenido infecciones recientes o en curso o si ha recibido recientemente una vacuna. Se debe buscar asesoramiento médico de inmediato si el paciente presenta fiebre u otros signos de infección. Se debe informar a los pacientes o a los cuidadores que algunas infecciones pueden ser potencialmente graves y mortales. Advierta a los pacientes que reciben corticoesteroides que eviten la exposición a la varicela o al sarampión y que avisen a su proveedor de atención médica de inmediato si se contagia.</li>
								<li>EMFLAZA puede provocar un aumento de la presión arterial y retención de líquido. Si esto ocurre, puede ser necesario restringir la sal en la alimentación y, además, complementarla con potasio.</li>
								<li>Existe un mayor riesgo de desarrollar un orificio en el estómago o los intestinos en pacientes con ciertos trastornos estomacales o intestinales cuando toman corticoesteroides como EMFLAZA.</li>
								<li>EMFLAZA puede provocar cambios graves en el comportamiento y el estado de ánimo. Solicite atención médica al proveedor de atención médica si se desarrollan cambios en el comportamiento o el estado de ánimo.</li>
								<li>Existe un riesgo de osteoporosis con el uso prolongado de EMFLAZA, lo cual puede provocar fracturas vertebrales y de huesos largos.</li>
								<li>EMFLAZA puede provocar cataratas o glaucoma y un proveedor de atención médica debe controlar estas afecciones si se continúa con el tratamiento con corticoesteroides durante más de 6 semanas.</li>
								<li>Las vacunas deben estar al día de acuerdo con la guía de inmunización antes de iniciar el tratamiento con EMFLAZA. Las vacunas elaboradas con microbios vivos o vacunas atenuadas deben administrarse al menos de 4 a 6 semanas antes de iniciar el tratamiento con EMFLAZA. No se deben usar vacunas vivas atenuadas o elaboradas con microbios vivos en pacientes que toman EMFLAZA.</li>
								<li>EMFLAZA puede provocar erupciones cutáneas graves. Busque atención médica al primer signo de erupción.</li>
								<li>Se han producido casos raros de anafilaxia en pacientes que reciben tratamiento con corticoesteroides, incluido EMFLAZA.</li>
							</ul>
							<h3>¿Qué debo decirle a mi proveedor de atención médica?</h3>
							<p>Informe al proveedor de atención médica sobre todas las afecciones médicas, incluso si la paciente:</p>
							<ul className="bulleted-list">
								<li>Está embarazada o planea quedar embarazada. EMFLAZA® (deflazacort) puede dañar al bebé en gestación.</li>
								<li>Está amamantando o planea amamantar. EMFLAZA puede aparecer en la leche materna y podría afectar a un niño lactante.</li>
							</ul>
							<p>Ciertos medicamentos pueden provocar una interacción con EMFLAZA. Informe a su profesional de atención médica de todos los medicamentos que esté tomando, incluidos los medicamentos de venta libre (como la insulina, la aspirina u otros antinflamatorios no esteroideos), suplementos dietéticos y productos a base de hierbas. Durante el tratamiento, puede ser necesario un tratamiento alternativo, un ajuste de la dosis o una(s) prueba(s) especial(es).</p>
							<h3>¿Cuáles son los efectos secundarios de EMFLAZA?</h3>
							<p>Los efectos secundarios más frecuentes de EMFLAZA son hinchazón facial o aspecto cushingoide, aumento de peso, aumento del apetito, infección de las vías respiratorias altas, tos, micción diurna frecuente, crecimiento no deseado del cabello, obesidad central y resfriados. Estos no son todos los efectos secundarios posibles de EMFLAZA. Llame a su médico para obtener asesoramiento médico sobre los efectos secundarios.</p>

							<p className="font-bold text-emflaza-blue-600">Para informar un evento adverso, llame al <KfAnchorLink url="tel:18665624620" target="_blank" rel="noreferrer" linkClass='underline'>1-866-562-4620</KfAnchorLink> o envíe un correo electrónico a <KfAnchorLink url="mailto:usmedinfo@ptcbio.com" target="_blank" rel="noreferrer" linkClass="underline">usmedinfo@ptcbio.com.</KfAnchorLink> También puede informar los efectos secundarios a la FDA llamando al <KfAnchorLink url="tel:18003321088" target="_blank" rel="noreferrer" linkClass='underline'>1-800-FDA-1088</KfAnchorLink> o visitando <a href="https://www.fda.gov/medwatch" target='_blank' rel="noreferrer" className="underline">www.fda.gov/medwatch.</a></p>

							<p>PTC <span className='italic'>Cares</span>™ es una marca comercial de PTC Therapeutics, Inc.<br /> Emflaza<sup>®</sup> es una marca comercial registrada de PTC Therapeutics, Inc.<br /> © 2023 PTC Therapeutics, Inc. Todos los derechos reservados.<br /> {getJobCode()}</p>
						</div>
					}
                </div>
            </div>
        </>
	);
};

export default TranscriptISI;
