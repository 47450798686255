import React from "react";
import PropTypes from "prop-types";
import { KfAnchorLink } from "@klickinc/kf-react-components";
import "./styles.scss";
const Isi = ({ isFullIsiShown }) => {
	return (
		<div className="isi font-gotham">
			<h2 className="mb-4 font-bold uppercase text-emflaza-blue-200">
                Indicación e información de seguridad importante para emflaza<sup>®</sup>
                {" "}
				<span className="lowercase">(DEFLAZACORT)</span>
			</h2>
			{isFullIsiShown && (
				<div className="isi__indication mb-4 block">
					<h3 className="mb-4 font-bold text-emflaza-blue-200 uppercase">
                        Indicación
					</h3>
					<p>
                    EMFLAZA® está indicado para el tratamiento de la distrofia muscular de Duchenne en pacientes a partir de los 2 años de edad.
					</p>
				</div>
			)}
			<div className="isi__safety-information">
				<h3 className="mb-1 font-bold text-emflaza-blue-200 uppercase">
                    Información de seguridad importante
				</h3>
				<p className="mb-4">
                    <span className='text-emflaza-blue-600 font-bold'>Contraindicaciones:</span> No utilizar si es alérgico al deflazacort o a alguno de los componentes inactivos de EMFLAZA.
				</p>
				<p><span className='text-emflaza-blue-600 font-bold'>No deje de tomar EMFLAZA ni cambie la cantidad que está tomando</span> sin consultar antes a su profesional de atención médica, ya que puede ser necesario reducir gradualmente la dosis para disminuir el riesgo de insuficiencia suprarrenal y “síndrome de abstinencia” de esteroides. Puede producirse insuficiencia suprarrenal aguda si se retiran los corticoesteroides de forma repentina, lo cual puede ser mortal. También puede producirse un “síndrome de abstinencia” de esteroides aparentemente no relacionado con la insuficiencia corticosuprarrenal tras la discontinuación abrupta de los corticoesteroides. Es posible que sea necesario aumentar la dosis de los pacientes que ya estén tomando corticoesteroides en momentos de estrés.</p>
                <p><span className='text-emflaza-blue-600 font-bold'>No deje de tomar EMFLAZA ni cambie la cantidad que está tomando</span> sin consultar antes a su profesional de atención médica, ya que puede ser necesario reducir gradualmente la dosis para disminuir el riesgo de insuficiencia suprarrenal y “síndrome de abstinencia” de esteroides. Puede producirse insuficiencia suprarrenal aguda si se retiran los corticoesteroides de forma repentina, lo cual puede ser mortal. También puede producirse un “síndrome de abstinencia” de esteroides aparentemente no relacionado con la insuficiencia corticosuprarrenal tras la discontinuación abrupta de los corticoesteroides. Es posible que sea necesario aumentar la dosis de los pacientes que ya estén tomando corticoesteroides en momentos de estrés.</p>
				<ul className="mt-3 mb-4 bulleted-list">
                <li><span className='text-emflaza-blue-600 font-bold'>Hiperglucemia:</span> Los corticoesteroides pueden aumentar la glucosa en sangre, empeorar una enfermedad diabética preexistente, predisponer a los pacientes con tratamiento a largo plazo a la diabetes mellitus y reducir el efecto de los medicamentos antidiabéticos. Monitoree la glucosa en sangre regularmente. En el caso de los pacientes con hiperglucemia, el tratamiento antidiabético debe iniciarse o ajustarse en consecuencia.</li>
                <li><span className='text-emflaza-blue-600 font-bold'>Aumento del riesgo de infección:</span> Informe a su profesional de atención médica si ha tenido infecciones recientes o en curso, o si ha recibido recientemente una vacuna o tiene cita para una vacuna. Asesórese con un médico de inmediato si tiene fiebre u otros signos de infección, ya que algunas infecciones pueden ser graves y mortales. Evite la exposición a la varicela o al sarampión; pero, si se contagia, debe asesorarse con un médico sin demora.</li>
                <li><span className='text-emflaza-blue-600 font-bold'>Alteraciones en la función cardiovascular/renal</span> EMFLAZA puede provocar un aumento de la presión arterial, retención de sal y agua, o una disminución de los niveles de potasio y calcio. Si esto ocurre, puede ser necesario restringir la sal en la alimentación y, además, complementarla con potasio.</li>
                <li><span className='text-emflaza-blue-600 font-bold'>Alteraciones conductuales y del estado de ánimo:</span> Existe la posibilidad de que haya cambios de comportamiento y estado de ánimo graves con EMFLAZA, por lo que debe asesorarse con un médico si se desarrollan síntomas psiquiátricos.</li>
                <li><span className='text-emflaza-blue-600 font-bold'>Efectos en los huesos:</span> Existe un riesgo de osteoporosis o disminución de la densidad mineral ósea con el uso prolongado de EMFLAZA, lo cual puede provocar fracturas vertebrales y de huesos largos.</li>
                <li><span className='text-emflaza-blue-600 font-bold'>Efectos en el crecimiento y el desarrollo:</span> El uso a largo plazo de corticoesteroides, incluido EMFLAZA, puede ralentizar el crecimiento y el desarrollo de los niños.</li>
                <li><span className='text-emflaza-blue-600 font-bold'>Efectos oftálmicos:</span> EMFLAZA puede causar cataratas, infecciones oculares y glaucoma, por lo que debe monitorearse si el tratamiento con corticoesteroides continúa durante más de 6 semanas.</li>
                <li><span className='text-emflaza-blue-600 font-bold'>Vacunación:</span> No se recomienda la administración de vacunas elaboradas con microbios vivos o vacunas atenuadas en pacientes que reciben EMFLAZA. Las vacunas elaboradas con microbios vivos o vacunas atenuadas pueden administrarse al menos de 4 a 6 semanas antes de iniciar el tratamiento con EMFLAZA.</li>
                <li><span className='text-emflaza-blue-600 font-bold'>Erupciones cutáneas graves:</span> Busque atención médica al primer signo de erupción.</li>
                <li><span className='text-emflaza-blue-600 font-bold'>Interacciones medicamentosas:</span> Ciertos medicamentos pueden provocar una interacción con EMFLAZA. Informe a su profesional de atención médica de todos los medicamentos que esté tomando, incluidos los medicamentos de venta libre (como la insulina, la aspirina u otros antinflamatorios no esteroideos), suplementos dietéticos y productos a base de hierbas. Durante el tratamiento, puede ser necesario un tratamiento alternativo, un ajuste de la dosis o una(s) prueba(s) especial(es). </li>
				</ul>
				<p>
                    <span className='text-emflaza-blue-600 font-bold'>Los efectos secundarios frecuentes que podrían producirse con EMFLAZA incluyen:</span> Hinchazón facial o aspecto cushingoide, aumento de peso, aumento del apetito, infección de las vías respiratorias altas, tos, micción diurna frecuente, crecimiento no deseado del cabello, obesidad central y resfriados.
				</p>
				<p className="mb-4">
                    Los efectos secundarios frecuentes que podrían producirse con EMFLAZA incluyen: Hinchazón facial o aspecto cushingoide, aumento de peso, aumento del apetito, infección de las vías respiratorias altas, tos, micción diurna frecuente, crecimiento no deseado del cabello, obesidad central y resfriados.
				</p>
				<p className="mb-4 text-emflaza-blue-600 font-bold">
                    Consulte la Ficha técnica completa que se adjunta.
				</p>
				<p>
                    Para notificar sospechas de REACCIONES adversas, póngase en contacto con PTC Therapeutics, Inc. llamando al <KfAnchorLink url="tel:18665624620" target="_blank" linkClass="link link--light-blue">1-866-562-4620</KfAnchorLink> o por correo electrónico a <KfAnchorLink url="mailto:pharmacovigilance@ptcbio.com" target="_blank" linkClass="link link--light-blue underline">Pharmacovigilance@PTCBio.com</KfAnchorLink> o con la FDA llamando al <KfAnchorLink url="tel:18003321088" target="_blank" linkClass="link link--light-blue">1-800-FDA-1088</KfAnchorLink> o visitando <a href='https://www.fda.gov/medwatch' target="_blank" rel="noreferrer" className='link link--light-blue underline'>www.fda.gov/medwatch</a>.
				</p>
			</div>
		</div>
	);
};

Isi.defaultProps = {
	isFullIsiShown: true,
};

Isi.propTypes = {
	isFullIsiShown: PropTypes.bool,
};

export default Isi;
