import AppContext from "@src/context";
import React, { useEffect, useRef, useState, useContext } from "react";
import { KfSticky } from "@klickinc/kf-react-components";
import { debounce, bounds, getWinSize } from "@klickinc/kf-react-components";
import GTM from "@utils/GTM";
import Isi from "@components/content/isi/Isi";
import SpanishIsi from "@components/content/isi/SpanishIsi";
import "./styles.scss";
import { handleFBQ } from '@utils/FBQ';

const Sidebar = ({pathname}) => {
	const { webinarVisible, isBannerVisible } = useContext(AppContext);

	const containerEl = useRef();
	const headerEl = useRef();
	const footerEl = useRef();
	const isiRef = useRef();
	const webinarDriver = useRef();

	const [leftPos, setLeftPos] = useState(-1000);
	const [threshold, setThreshold] = useState(0);
	const sidebarWidth = 335;
	const yStartOffset = 40;
	const btnHeight = 68;
	const margin = 17;

	useEffect(() => {
		GTM.initIsi();

		const handleResize = () => {
			footerEl.current = document.body.querySelector("footer");
			containerEl.current =
				document.body.querySelector("header .container");
			headerEl.current = document.body.querySelector("header");
			webinarDriver.current =
				document.body.querySelector("#webinar-driver");
			isiRef.current = document.body.querySelector(".sidebar .isi");
			const resourceBannerEl = document.body.querySelector(".resource-banner");

			if (containerEl.current && footerEl.current && headerEl.current) {
				let ypos, height;
				const windowHeight = getWinSize().height;
				const windowScrollY = window.scrollY || window.pageYOffset;
				const containerBounds = bounds(containerEl.current);
				const headerBounds = bounds(headerEl.current);
				const footerBounds = bounds(footerEl.current);
				const resourceBannerBounds = resourceBannerEl && bounds(resourceBannerEl);
				let webinarDriverBounds = null;

				if (webinarVisible) {
					webinarDriverBounds = webinarDriver.current
						? bounds(webinarDriver.current)
						: null;
				}

				const xpos =
					(containerBounds.left ? containerBounds.left : -50) +
					containerBounds.width -
					sidebarWidth;
				const thresholdPoint = isBannerVisible ? headerBounds.height +
				(webinarDriverBounds ? webinarDriverBounds.height : 0) +
				yStartOffset + resourceBannerBounds.height : headerBounds.height +
				(webinarDriverBounds ? webinarDriverBounds.height : 0) +
				yStartOffset;
				const footerOffset =
					windowHeight < footerBounds.top
						? 0
						: windowHeight - footerBounds.top;

				if (windowScrollY > threshold) {
					ypos = 0;
					height = `${
						windowHeight - footerOffset - btnHeight - margin * 2
					}px`;
				} else {
					ypos = containerBounds.height + yStartOffset;
					height = `${
						windowScrollY +
						windowHeight -
						footerOffset -
						ypos -
						headerBounds.height
						- 50 // Added to fit ISI to the screen height.
					}px`;
				}

				setLeftPos(xpos);
				setThreshold(thresholdPoint);
				if (isiRef && isiRef.current) {
					isiRef.current.style.height = height;
				}
			}
		};

		const resizeListener = debounce(handleResize, 5);

		window.addEventListener("resize", resizeListener);
		window.addEventListener("scroll", resizeListener);
		setTimeout(() => handleResize(), 5);

		return () => {
			window.removeEventListener("resize", resizeListener);
			window.removeEventListener("scroll", resizeListener);
			GTM.reset();
		};
    },[ threshold, webinarVisible ])
	return (
		<div
			className="sidebar"
			style={{ left: `${leftPos}px`, top: `${threshold}px` }}
		>
			<KfSticky xOffset={leftPos} threshold={threshold}>
				<a
					href="/prescription-start-form.pdf"
					target="_blank"
					className="prescription-btn"
					onClick={() => handleFBQ('InitiateCheckout')}
				>
					<img src="/images/icons/icon-prescription.png" alt="" className="icon_prescription" width='45' height='45' />
					{
						pathname === '/es' ?
						<span>Formulario de inicio de receta</span>
						:
						<span>Prescription Start Form</span>

					}
				</a>
				<div className="overflow-hidden" tabIndex="0">
					{
						pathname === '/es' ?
						<SpanishIsi />
						:
						<Isi />

					}
				</div>
			</KfSticky>
		</div>
	);
};

export default Sidebar;
