import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { isMobile, debounce } from "@klickinc/kf-react-components";

/* Set Global States here. */
const defaultState = {
	modalOpen: false,
    setModalOpen: () => {},
    externalUrl: null,
    setExternalUrl: () => {},
	modalType: null,
	setModalType: () => {},
	pageClass: "",
	setPageClass: () => {},
	isExpanded: false,
	setExpanded: () => {},
	mobile: false,
	setMobile: () => {},
	webinarVisible: true,
	showWebinar: () => {},
	videoEls: null,
    setVideoEls: () => {},
	isBannerVisible: true,
	setIsBannerVisible: () => {},
};

const AppContext = React.createContext(defaultState);

const AppProvider = ({ children }) => {
	const [modalOpen, setModalOpen] = useState(defaultState.modalOpen)
	const [url, setUrl] = useState(defaultState.externalUrl)
	const [modalType, setModalType] = useState(defaultState.modalType)
	const [isExpanded, setExpanded] = useState(defaultState.isExpanded);
	const [pageClass, setPageClass] = useState("");
	const [mobile, setMobile] = useState(defaultState.mobile);
	const [webinarVisible, showWebinar] = useState(defaultState.webinarVisible);
	const [videoEls, setVideoEls] = useState(defaultState.videoEls);
	const [isBannerVisible, setIsBannerVisible] = useState(defaultState.isBannerVisible);

	const store = {
		modalOpen: modalOpen,
        setModalOpen: setModalOpen,
        externalUrl: url,
        setExternalUrl: setUrl,
		modalType: modalType,
		setModalType: setModalType,
		isExpanded: isExpanded,
		setExpanded: setExpanded,
		pageClass: pageClass,
		setPageClass: setPageClass,
		mobile: mobile,
		setMobile: setMobile,
		webinarVisible: webinarVisible,
		showWebinar: showWebinar,
        videoEls: videoEls,
        setVideoEls: setVideoEls,
		isBannerVisible: isBannerVisible,
		setIsBannerVisible: setIsBannerVisible,
	};

	useEffect(() => {
		if (typeof window !== "undefined") {
			if (window.location.href.indexOf("ed=1") > -1) {
				runScreenshots();
			}
		}

		const mobileHandler = () => {
			setMobile(isMobile());
		};

		const mobileCheck = debounce(mobileHandler, 18);
		mobileCheck();
		if (typeof window !== "undefined") {
			document.addEventListener("resize", mobileCheck);
		}

		return () => {
			document.removeEventListener("resize", mobileCheck);
		};
	}, []);

	// actions here, are only applied when the page is completely loaded/rendered
	const runScreenshots = () => {
		const url = window.location.href;
		document.querySelector("html").classList.add("ed");

		if (url.includes("ssIsiNonFixed")) {
			const isi = document.querySelector(".sticky-tray");

			if (isi) {
				isi.style.display = "none";
			}
		}

		if (url.includes("ssAnimationOff")) {
			// code to turn off animation
			const myanimations = document.querySelectorAll("img");

			for (const image of myanimations) {
				image.classList.remove("gatsby-image-wrapper");
			}
		}

		if ( url.includes("resourceBanner") ) {
			setIsBannerVisible(true)
		}
	};

	return <AppContext.Provider value={store}>{children}</AppContext.Provider>;
};

AppProvider.propTypes = {
	children: PropTypes.node,
};

export default AppContext;

export { AppProvider };
