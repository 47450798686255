import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router'

const MenuItems = ({ isNavOpen, ariaLabel, addedId, menuLinks }) => {
    const navRef = useRef();
	const { pathname } = useLocation();

	const slugify = (str) => {
		str = str.replace(/^\s+|\s+$/g, ''); // trim
		str = str.toLowerCase();

		// remove accents, swap ñ for n, etc
		var from = 'àáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
		var to = 'aaaaaeeeeiiiioooouuuunc------';

		for (var i = 0, l = from.length; i < l; i++) {
			str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
		}

		str = str
			.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
			.replace(/\s+/g, '-') // collapse whitespace and replace by -
			.replace(/-+/g, '-'); // collapse dashes

		return str;
	};

    const closeHeader = function (e) {
        const el = document.querySelector('nav.kf-menu ul');

        if (!el.contains(e.target)) {
            const btn = el.querySelector('.kf-menu-link button[aria-expanded="true"]');
            if (btn) {
                btn.setAttribute('aria-expanded', 'false');
                btn.nextElementSibling.classList.remove('kf-submenu--open');
                btn.nextElementSibling.classList.add('kf-submenu--closed');
            }
        }
    };

    useEffect(() => {
		closeAllSubMenus()
    }, [pathname]);

    const closeAllSubMenus = () => {
        if (navRef.current) {
            const allButtons = navRef.current.querySelectorAll('.kf-menu-link button');
            allButtons.forEach((btn) => {
                btn.setAttribute('aria-expanded', false);
            });
            const allSubMenus = navRef.current.querySelectorAll('.kf-submenu');
            allSubMenus.forEach((subMenu) => {
                subMenu.classList.remove('kf-submenu--open');
                subMenu.classList.add('kf-submenu--closed');
            });
        }
    };

    const subMenuExpanded = (e) => {
        const clickedEl = e.target;
        const clickedSubMenuOpen = clickedEl.nextSibling.classList.contains('kf-submenu--open');

		closeAllSubMenus();
		if (!clickedSubMenuOpen && clickedEl.nextSibling) {
			clickedEl.nextSibling.classList.remove('kf-submenu--closed');
			clickedEl.nextSibling.classList.add('kf-submenu--open');
			e.target.setAttribute('aria-expanded', true);
		} else if (clickedSubMenuOpen && clickedEl.nextSibling) {
			clickedEl.nextSibling.classList.add('kf-submenu--closed');
			clickedEl.nextSibling.classList.remove('kf-submenu--open');
			e.target.setAttribute('aria-expanded', false);
		}
    };

    useEffect(() => {
		if (typeof window !== 'undefined') {
            window.addEventListener('click', closeHeader);
        }

		let allOpenableSubMenus;
        if (navRef.current) {
            allOpenableSubMenus = navRef.current.querySelectorAll('.kf-menu-link button');
            allOpenableSubMenus.forEach((openableSubMenu) => {
				openableSubMenu.addEventListener('click', (e) => subMenuExpanded(e));
            });
        }

		return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('click', closeHeader);
            }

			if (navRef.current) {
                allOpenableSubMenus.forEach((openableSubMenu) => {
                    openableSubMenu.removeEventListener('click', (e) => subMenuExpanded(e));
                });
			}
        };
    }, []);

    const navLinks = menuLinks.map((item, i) => {
        const subItems = () => {
            return (
                <ul
                    className='kf-submenu kf-submenu--closed'
                    id={`kf-submenu-${slugify(item.linkName)}`}>
                    {item.subMenu.map((sitem, s) => (
                        <li key={s} className={`kf-submenu-li ${sitem.class}`}>
                            <a
                                onClick={sitem?.clickFn}
                                href={sitem.subLink}
                                aria-current='page'>
                                {sitem.subName}
                            </a>
                        </li>
                    ))}
                </ul>
            );
        };
        return (
            <li key={i} className={`kf-menu-link ${item.class ? item.class : ''}`}>
                {item.subMenu ? (
                    <button
                        aria-haspopup={true}
                        aria-controls={`kf-submenu-${slugify(item.linkName)}`}>
                        {item.linkName}
                    </button>
                ) : (
                    <a href={item.link} onClick={item?.clickFn}>
                        {item.linkName}
                    </a>
                )}
                {item.subMenu && subItems()}
            </li>
        );
    });

    return (
        <ul
            ref={navRef}
            id={addedId}
            aria-label={ariaLabel}
            className={isNavOpen ? 'open' : null}>
            {navLinks}
        </ul>
    );
};
MenuItems.defaultProps = {
    isNavOpen: false,
    ariaLabel: '',
    addedId: '',
};

MenuItems.propTypes = {
    isNavOpen: PropTypes.bool,
    ariaLabel: PropTypes.string,
    addedId: PropTypes.string.isRequired,
    menuLinks: PropTypes.arrayOf(
        PropTypes.shape({
            linkName: PropTypes.string,
            link: PropTypes.string,
            class: PropTypes.string,
            subMenu: PropTypes.arrayOf(
                PropTypes.shape({
                    subName: PropTypes.string,
                    subLink: PropTypes.string,
                    class: PropTypes.string,
                })
            ),
        })
    ).isRequired,
};
export default MenuItems;
