import React, { useState, useRef, useEffect } from 'react';
import { globalHistory, useLocation } from '@reach/router'
import { debounce, isMobile } from '@klickinc/kf-react-components';
import HeaderLinks from './HeaderLinks';
import Image from '@components/utility/Image';
import MenuItems from '@components/global/header/MenuItems';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { withArtDirection, getImage } from 'gatsby-plugin-image';
import './styles.scss';

const Header = () => {
	const { pathname, hash } = useLocation();
	const headerRef = useRef();
	const [open, setOpen] = useState(false);

	const closeMenu = () => {
		setOpen(false)
	}

	const handleScroll = () => {
		const windowScrollY = window.scrollY || window.pageYOffset;
		if (windowScrollY > 100) {
			closeMenu()
		}
	}

	const query = useStaticQuery(graphql`
		query {
			headerLogo: file(relativePath: {eq: "logos/header-logo.png"}) {
				childImageSharp {
					gatsbyImageData
				}
			},
			headerLogoM: file(relativePath: {eq: "logos/header-logo-mobile.png"}) {
				childImageSharp {
					gatsbyImageData
				}
			},
		}
	`);

	const image_headerLogo = withArtDirection(getImage(query.headerLogo), [
		{
			media: "(max-width: 640px)",
			image: getImage(query.headerLogoM),
		},
	]);

	// TO DO: remove this when Fractals stable release is out
	// split first dropdown item into italic content and superscript (because of current KfMenu limitation)
	const replaceCopyOfFirstDropDownElement = () => {
		const firstDropdownItem = document.querySelector('.kf-submenu > li:nth-child(1) a');
		firstDropdownItem.innerHTML = `PTC <span class='italic'>Cares</span><sup>TM</sup>`;
	}

	const handleActiveNav = (v) => {
		const getCurrentLink = (menuItems, pathname) => {
			const output = {};

			menuItems.forEach(el => {
				if (!el.subMenu) {
					if (el.link === pathname) {
						output.isDropdown = false
						output.link = el.link
					}
				} else if (el.subMenu) {
					el.subMenu.forEach(subEl => {
						if (subEl.subLink === pathname) {
							output.isDropdown = true
							output.link = subEl.subLink
							output.parentLink = el.link
						}
					})
				}
			})

			return output;
		}

		const clearAllHoverStates = () => {
			headerRef.current.querySelectorAll('li > a, li > button').forEach(link => {
				link.classList.remove('current-page-hover');
				link.removeAttribute('aria-current');
			});
		}

		clearAllHoverStates();
		const currentLink = getCurrentLink(HeaderLinks, v);

		if (Object.keys(currentLink).length) {
			const link = headerRef.current.querySelector(`.kf-menu-link-${currentLink.link.replace("/", "")} a`);
			link.setAttribute('aria-current', 'page');

			if (currentLink.isDropdown) {
				headerRef.current.querySelector(`.kf-menu-link-${currentLink.parentLink.replace("/", "")} button`)?.classList.add('current-page-hover');
			} else {
				link?.classList.add('current-page-hover');
			}
		};
	}

	const scrollToAnchor = (hash) => {
		if (hash) {
			// get the hash if there's one. Get it whether there's only hash or wether there's extra url params.
			const hashSelector = hash.match(/#([^?#]+)/)[0];
			if (hashSelector) {
				const item = document.querySelector(hashSelector)
				setTimeout(() => {
					item?.scrollIntoView({behavior: "smooth"})
				}, 1000);
			}

		}
	}

	useEffect(() => {
		handleActiveNav(pathname)
	},[pathname])

	useEffect(() => {
		// TODO: remove the next line when Fractals 1.0 is used and place lang attr on spanish link.
		headerRef.current.querySelector('.kf-menu-link-es a')?.setAttribute('lang', 'es');
		replaceCopyOfFirstDropDownElement();
		scrollToAnchor(hash)

		if (isMobile()){
			const scrollListener = debounce(handleScroll, 18);
			document.addEventListener("scroll", scrollListener);
		}

		return globalHistory.listen(() => {
			closeMenu();

			if (isMobile()){
				const scrollListener = debounce(handleScroll, 18);
				document.removeEventListener("scroll", scrollListener);
			}
		})

	}, []);

	return (
		<header ref={headerRef} lang="en">
			<div className="eyebrow-nav">
				<div className='container top-links'>
					<div className='border-r border-emflaza-blue-100 lg:border-0'>
						<Link to='/isi' className='link link--white lg:link--light-blue'>Important Safety Information</Link>
					</div>
					<div className='min-h-[60px] flex items-center mobile:min-h-0 border-r border-emflaza-blue-100 lg:border-0'>
						<a className="kf-anchorlink link link--white lg:link--light-blue" href="/prescribing-information.pdf" target="_blank" rel="noopener noreferrer">Prescribing Information</a>
					</div>
					<div className='hidden lg:block'>
						<a className="kf-anchorlink link link--light-blue" href="https://hcp.emflaza.com/" target="_blank" rel="noopener noreferrer">HCP Site</a>
					</div>
					<div>
						<Link to='/sign-up-for-updates' className='link link--white lg:link--light-blue'>Sign up for<br className='block md:hidden' /> Updates</Link>
					</div>
				</div>
			</div>

			{/* Main nav */}
			<div className='logo-nav'>
				<div className="container lg:pl-4 flex items-center lg:items-start h-[5.5rem]">
					<div className="relative bg-white h-full flex items-center max-w-[7.688rem] md:pb-1 px-3 lg:h-[7.25rem] lg:max-w-[14.5rem] lg:px-12 lg:-translate-y-7">
						<Link to='/' className="header-logo">
							<span className='sr-only'>EMFLAZA® (deflazacort) homepage</span>
							<span aria-hidden='true'>
								<Image loading='eager' imageData={image_headerLogo} alt="EMFLAZA® (deflazacort) homepage" objectFit="contain" />
							</span>
						</Link>
					</div>
					<div className='main-nav-items flex items-center justify-center flex-1 h-full'>
						<nav className='kf-menu navigation header-menu'>
							<button
								id='button-id'
								onClick={() => setOpen(!open)}
								aria-expanded={open}
								aria-haspopup={true}
								aria-label='open nav menu'
								aria-controls='site-navigation'>
								<span className='nav__hamburger-menu'>
									<span className='nav__hamburger-inner'></span>
									<span className='nav__hamburger-inner'></span>
									<span className='nav__hamburger-inner'></span>
								</span>
							</button>
							<MenuItems
								addedId='site-navigation'
								ariaLabel='Menu'
								isNavOpen={open}
								menuLinks={HeaderLinks}
							/>
						</nav>
					</div>
				</div>
			</div>
		</header>
	)
}

export default Header;
