import AppContext from "@src/context";
import React, { useState, useEffect, useContext } from "react";
import { navigate } from "gatsby";
import PropTypes from "prop-types";
import GTM from "@utils/GTM";
import { isEDMode } from '@src/utils/helper.js'
import { handleFBQ } from '@utils/FBQ';
import { isMobile, debounce } from "@klickinc/kf-react-components";
import Header from "@components/global/header/Header";
import Footer from "@components/global/footer/Footer";
import Isi from "@components/content/isi/Isi";
import TranscriptISI from "@components/page-sections/transcript-isi/TranscriptISI";
import SpanishIsi from "@components/content/isi/SpanishIsi";
import Sidebar from "@components/global/sidebar/Sidebar";
import StickyTray from "@components/global/sticky-tray/StickyTray";
import SkipToContent from "@components/global/SkipToContent";
import ResourceBanner from  '@components/content/resource-banner/ResourceBanner';
import { useLocation } from "@reach/router";
import ExitModal from '@components/structure/modal/Modal.Exit.jsx'

const Layout = ({ children }) => {
	const [mobile, setAsMobile] = useState(true);
	const [loaded, setLoaded] = useState("opacity-0");
	const { setExpanded, pageClass, modalOpen, modalType, isBannerVisible } = useContext(AppContext); //destructuring ctx
	const { hash, pathname, href } = useLocation();
	const spanishPaths = ['/es', '/video-transcript/haciendo-un-cambio'];

	const mobileCheck = () => {
		setAsMobile(isMobile("(min-width: 0px) and (max-width: 1199px)"));
	};

	useEffect(() => {
		GTM.init();

		if (hash) {
			if (hash === "#isi-jump") {
				navigate("/isi");
			}
		}


		const mobileCheckListener = debounce(mobileCheck, 18);
		window.addEventListener("resize", mobileCheckListener);
		window.addEventListener("orientationchange", mobileCheckListener);
		mobileCheck();

		if (typeof window !== "undefined") {
			// ss
			if(isEDMode()) {
				if (href.includes('ssLandingState')) {
					document.querySelector("html").classList.add('ssLandingState');
				}
				if (href.includes('ssCookieProBanner')) {
					setTimeout(() => {
						document.querySelector("html").classList.add('ssCookieProBanner');
					}, 2000)
				}
				if (href.includes('ssCookieProPreference')) {
					document.querySelector("html").classList.add('ssCookieProPreference');
				}
				if (href.includes('ssHideCookieBanner')) {
					document.querySelector("html").classList.add('ssHideCookieBanner');
				}
				if (href.includes('ssFixedIsiBottom')) {
					document.querySelector("html").classList.add('ssFixedIsiBottom');
					document.querySelector(".sticky-tray").classList.remove('hidden');
				}
			}

			if (href.includes("ssFullISI")) {
				if (!isMobile()) {
					setTimeout(() => {
						const isi = document.querySelector(".isi");

						const homePageVideo = document.querySelector(".page--home .bg-2-image .video");

						if (homePageVideo) {
							homePageVideo.style.paddingBottom = "18rem";
						}

						if (isi) {
							isi.classList.add("fullISI");
						}
					}, 100);
				} else {
					setTimeout(() => {
						const isi = document.querySelector("#isi");
						if (isi) isi.style.display = "none";

						document.querySelector(".sticky-tray").classList.add("hidden");
						document.querySelector(".sticky-tray-ss").classList.add("landingState");
					}, 100);
				}
			}

			// mobile menu SS start
			if (isMobile("(min-width: 0px) and (max-width: 1199px)")) {
				if (href.includes("ssHamburgerMenu")) {
					document.querySelector("html").classList.add("overFlow");
					setTimeout(() => {
						document.getElementById(
							"button-id"
						).ariaExpanded = true;
					}, 100);

					setExpanded(true);
				}
			}
			// mobile menu SS END

			if (href.includes("ssAccordionOpen")) {
				setTimeout(() => {
					const accordionsBtns = document.querySelectorAll(
						".kf-accordion__trigger button"
					);
					accordionsBtns.forEach((el) => {
						el.click();
						el.parentElement.nextElementSibling.classList.add(
							"ssRemoveFocus"
						);
						window.scrollTo(0, 0);
					});
				}, 100);
			}

			// about-emflaza page image cut-off fix
			if (
				href.includes("about-emflaza") &&
				href.includes("ssAccordionOpen")
			) {
				if (isMobile()) {
					setTimeout(() => {
						const image = document.querySelector(".bg-boy");
						image.style.zIndex = "50";
					}, 100);
				}
			}

			setTimeout(() => {
				if (href.includes("scroll-isi")) {
					const isi = document.querySelector(".isi");
					if (isi) {
						if (href.includes("scroll-isi-1")) {
							isi.scroll(0, 600);
						}

						if (href.includes("scroll-isi-2")) {
							isi.scroll(0, 1200);
						}

						if (href.includes("scroll-isi-3")) {
							isi.scroll(0, 1800);
						}

						if (href.includes("scroll-isi-4")) {
							isi.scroll(0, 2400);
						}

						if (href.includes("scroll-isi-5")) {
							isi.scroll(0, 3000);
						}
					}
				}
			}, 300);
		}

		setLoaded("opacity-100");

		return () => {
			window.removeEventListener("resize", mobileCheckListener);
			window.removeEventListener(
				"orientationchange",
				mobileCheckListener
			);
			GTM.reset();
		};
	}, []);

	// a11y fix for lang attribute for html tag based on the current page.
	useEffect(() => {
		if (spanishPaths.includes(pathname)) {
			document.documentElement.setAttribute("lang", "es");
		} else {
			document.documentElement.setAttribute("lang", "en");
		}

		if (pathname === '/understanding-corticosteroids') {
			handleFBQ('ViewContent');
		} else if (pathname === '/about-emflaza') {
			handleFBQ('AddPaymentInfo');
		} else if (pathname === '/safety') {
			handleFBQ('Lead');
		} else if (pathname === '/dosing') {
			handleFBQ('Subscribe');
		} else if (pathname === '/ptc-cares-helps') {
			handleFBQ('AddtoWishlist');
		}

	}, [pathname]);

	return (
		<div
			className={`text-sm font-roboto text-emflaza-blue-600 antialiased ${pageClass}`}
		>
			<SkipToContent />
			{isBannerVisible && <ResourceBanner />}
			<Header />
			<main id="content">{children}</main>
			<div className={loaded}>
				{!mobile ? (
					<Sidebar
						pathname={pathname}
					/>
				) : (
					<>
					{ pathname.includes('/video-transcript') ?
						null
						:
						<>
							<StickyTray
								pathname={pathname}
							/>
							<div id="isi" className="inner-isi">
								<a
									href="/prescription-start-form.pdf"
									target="_blank"
									className="prescription-btn"
									onClick={() => handleFBQ('InitiateCheckout')}
								>
									<img src="/images/icons/icon-prescription.png" alt="" className="icon_prescription" width='45' height='45' />

								{
									pathname === '/es' ?
									<span>Formulario de inicio de receta</span>
									:
									<span>Prescription Start Form</span>
								}
								</a>
								<div className="container mt-4 mb-7">
									{pathname === '/es' ?
										<SpanishIsi />
										:
										<Isi />
									}
								</div>
							</div>
						</>
						}
					</>
				)}
				{pathname.includes('/video-transcript') && <TranscriptISI />}
			</div>
			{modalOpen && modalType === 'exit' &&
				<ExitModal />
			}
			<Footer />
		</div>
	);
};

export default Layout;

Layout.propTypes = {
	children: PropTypes.node,
};

Layout.defaultProps = {
	children: null,
};
