import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

const Image = ({ addedClass, imageData, alt, loading, objectFit }) => {
	const [images, setImages] = useState(null);
	const [lazyload, shouldLazyLoad] = useState(loading);
	useEffect(() => {
		if (typeof window !== 'undefined') {
			if (window.location.href.indexOf('ed=1') > -1) {
				shouldLazyLoad('eager');
			}
		}

		setImages(imageData);
	}, []);

	return (
		<div className='image'>
			{images && (<GatsbyImage
				image={images}
				alt={alt}
				className={addedClass}
				loading={lazyload}
				objectFit={objectFit}
			/>)}
		</div>
	);
};

Image.defaultProps = {
	addedClass: '',
	alt: '',
	loading: 'lazy',
	objectFit: 'cover',
};

Image.propTypes = {
	addedClass: PropTypes.string,
	alt: PropTypes.string,
	imageData: PropTypes.object.isRequired,
	loading: PropTypes.oneOf(['lazy', 'eager', 'auto']),
	objectFit: PropTypes.oneOf(['cover', 'contain']),
};

export default Image;
