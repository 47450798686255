import PropTypes from "prop-types"
import React, { useRef, useEffect, useContext } from "react"
import AppContext from "@src/context"
// Style Imports
import "./Modal.scss";

const Modal = ({ children, addedClass, closeModalFn }) => {
	const ctx = useContext(AppContext);
	const modalBody = useRef();
	const bodyEl = useRef();
	const activeEl = useRef(null);

	const focusLoop = (evt) => {
		if (modalBody && modalBody.current) {
			if (evt.keyCode === 27) {
				closeModalFn();
			}

			const tabPressed = evt.key === "Tab" || evt.keyCode === 9;

			if (!tabPressed) return;

			const elems = modalBody.current.querySelectorAll(
				"a[href]:not([disabled]), button:not([disabled])"
			);

			if (elems?.length) {
				const firstElem = elems[0];
				const lastElem = elems[elems.length - 1];
				if (evt.shiftKey) {
					if (document.activeElement === firstElem) {
						lastElem.focus();
						evt.preventDefault();
					}
				} else {
					if (document.activeElement === lastElem) {
						firstElem.focus();
						evt.preventDefault();
					}
				}
			}
		}
	};

	const disablePageScroll = (modalOpen) => {
		if (bodyEl && bodyEl.current) {
			if (modalOpen) {
				bodyEl.current.classList.add("modal-open");
			} else {
				bodyEl.current.classList.remove("modal-open");
			}
		}
	};

	const handleOverlayClick = (event) => {
		if (event.target.classList.contains("modal__overlay")) {
			ctx.setModalOpen(false);
			ctx.setExternalUrl(null);
			ctx.setModalType(null);
		}
	};

	useEffect(() => {
		activeEl.current = document.activeElement;
		bodyEl.current = document.body;
		disablePageScroll(true);
		modalBody.current.focus({ preventScroll: true });
		document.addEventListener("mousedown", handleOverlayClick);
		modalBody.current.addEventListener("keydown", focusLoop);

		return () => {
			document.removeEventListener("mousedown", handleOverlayClick);
			modalBody.current?.removeEventListener("keydown", focusLoop);
			disablePageScroll(false);
			activeEl.current.focus();
			activeEl.current = null;
		};
	}, []);

	return (
		<section className={"modal " + addedClass}>
			<div className="modal__overlay" onClick={closeModalFn} />
			<div ref={modalBody} tabIndex="0" className="modal__body">
				{children}
			</div>
		</section>
	);
};

Modal.propTypes = {
	children: PropTypes.node.isRequired,
	addedClass: PropTypes.string,
	closeModalFn: PropTypes.func,
};

Modal.defaultProps = {
	addedClass: "",
	closeModalFn: () => {},
};

export default Modal;
