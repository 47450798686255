import React from "react";
import PropTypes from "prop-types";
import { KfAnchorLink } from "@klickinc/kf-react-components";
import "./styles.scss";

const Isi = ({ isFullIsiShown }) => {
	return (
		<div className="isi font-gotham">
			<h2 className="mb-4 font-bold uppercase text-emflaza-blue-200">
				Indication &amp; Important Safety Information for EMFLAZA
				<sup>&reg;</sup>{" "}
				<span className="lowercase">(deflazacort)</span>
			</h2>
			{isFullIsiShown && (
				<div className="isi__indication mb-4 block">
					<h3 className="mb-1 font-bold text-emflaza-blue-200">
						What is{" "}
						<span className="uppercase">
							EMFLAZA<sup>&reg;</sup>
						</span>{" "}
						(deflazacort) used for?
					</h3>
					<p>
						EMFLAZA is a prescription medicine used to treat Duchenne muscular dystrophy (DMD) in patients 2&nbsp;years of age and older.
					</p>
				</div>
			)}
			<div className="isi__safety-information">
				<h3 className="mb-1 font-bold text-emflaza-blue-200">
					When should I not take{" "}
					<span className="uppercase">EMFLAZA</span>?
				</h3>
				<p className="mb-4">
					Do not use if you have had hypersensitivity, including
					allergic reactions, to deflazacort or any of the inactive
					ingredients.
				</p>
				<h3 className="mb-1 font-bold text-emflaza-blue-200">
					What warnings should I know about{" "}
					<span className="uppercase">EMFLAZA</span>?
				</h3>
				<ul className="mt-3 mb-4 bulleted-list">
					<li>
						EMFLAZA can cause changes in endocrine function. Do not
						stop taking EMFLAZA, or change the amount you are
						taking, without first checking with your healthcare
						provider, as there may be a need for gradual dose
						reduction to decrease the risk of adrenal insufficiency
						and steroid “withdrawal syndrome”. Acute adrenal
						insufficiency can occur if corticosteroids are withdrawn
						abruptly, and can be fatal. A steroid “withdrawal
						syndrome,” seemingly unrelated to adrenocortical
						insufficiency, may also occur following abrupt
						discontinuance of corticosteroids. For patients already
						taking corticosteroids during times of stress, the
						dosage may need to be increased.
					</li>
					<li>
						There is an increased risk of infection when taking
						EMFLAZA. Tell the healthcare provider if the patient has
						had recent or ongoing infections or if they have
						recently received a vaccine. Medical advice should be
						sought immediately if the patient develops fever or
						other signs of infection. Patients and/or caregivers
						should be made aware that some infections can
						potentially be severe and fatal. Warn patients who are
						on corticosteroids to avoid exposure to chickenpox or
						measles and to alert their healthcare provider
						immediately if they are exposed.
					</li>
					<li>
						EMFLAZA can cause an increase in blood pressure and
						water retention. If this occurs, dietary salt
						restriction and potassium supplementation may be needed.
					</li>
					<li>
						There is an increased risk of developing a hole in the
						stomach or intestines in patients with certain stomach
						or intestine disorders when taking corticosteroids like
						EMFLAZA.{" "}
					</li>
					<li>
						EMFLAZA can cause severe behavioral and mood changes.
						Seek medical attention from the health care provider if
						any behavioral or mood changes develop.
					</li>
					<li>
						There is a risk of osteoporosis with prolonged use of
						EMFLAZA, which can lead to vertebral and long bone
						fractures.
					</li>
					<li>
						EMFLAZA may cause cataracts or glaucoma and a health
						care provider should monitor for these conditions if
						corticosteroid therapy is continued for more than
						6&nbsp;weeks.
					</li>
					<li>
						Immunizations should be up-to-date according to
						immunization guidelines prior to starting therapy with
						EMFLAZA. Live&#8209;attenuated or live vaccines should
						be administered at least 4 to 6 weeks prior to starting
						EMFLAZA. Live&#8209;attenuated or live vaccines should
						not be used in patients taking EMFLAZA.
					</li>
					<li>
						EMFLAZA can cause serious skin rashes. Seek medical
						attention at the first sign of a rash.
					</li>
					<li>
						Rare instances of anaphylaxis have occurred in patients
						receiving corticosteroid therapy, including EMFLAZA.
					</li>
				</ul>
				<h3 className="mb-1 font-bold text-emflaza-blue-200">
					What should I tell my health care provider?
				</h3>
				<p>
					Tell the health care provider about all medical conditions,
					including if the patient:
				</p>
				<ul className="mt-3 mb-4 bulleted-list">
					<li>
						is pregnant or planning to become pregnant. EMFLAZA
						<sup>&reg;</sup> (deflazacort) can harm your unborn
						baby.{" "}
					</li>
					<li>
						is breastfeeding or planning to breastfeed. EMFLAZA may
						appear in breastmilk and could affect a nursing child.
					</li>
				</ul>
				<p className="mb-4">
					Certain medications can cause an interaction with EMFLAZA.
					Tell your healthcare provider of all the medicines you are
					taking, including over-the-counter medicines (such as
					insulin, aspirin or other NSAIDS), dietary supplements, and
					herbal products. Alternate treatment, dosage adjustment,
					and/or special test(s) may be needed during the treatment.
				</p>
				<h3 className="mb-1 font-bold text-emflaza-blue-200">
					What are the side effects of{" "}
					<span className="uppercase">EMFLAZA</span>?
				</h3>
				<p className="mb-4">
					The most common side effects of EMFLAZA include facial
					puffiness or Cushingoid appearance, weight increased,
					increased appetite, upper respiratory tract infection,
					cough, frequent daytime urination, unwanted hair growth,
					central obesity, and colds. These are not all of the
					possible side effects of EMFLAZA. Call your doctor for
					medical advice about side effects.
				</p>
				<p>
					<strong>
						To report an adverse event, please call{" "}
						<KfAnchorLink
							url="tel:18665624620"
							target="_blank"
							rel="noreferrer"
							linkClass="link link--light-blue"
						>
							1-866-562-4620
						</KfAnchorLink>{" "}
						or email at{" "}
						<KfAnchorLink
							url="mailto:usmedinfo@ptcbio.com"
							target="_blank"
							rel="noreferrer"
							linkClass="link link--light-blue"
						>
							usmedinfo@ptcbio.com
						</KfAnchorLink>
						. You may also report side effects to FDA at{" "}
						<KfAnchorLink
							url="tel:18003321088"
							target="_blank"
							rel="noreferrer"
							linkClass="link link--light-blue"
						>
							1-800-FDA-1088
						</KfAnchorLink>{" "}
						or at{" "}
						<a
							href="https://www.fda.gov/medwatch"
							target="_blank"
							rel="noreferrer"
							className="link link--light-blue">
							www.fda.gov/medwatch
						</a>
						.
					</strong>
				</p>
			</div>
		</div>
	);
};

Isi.defaultProps = {
	isFullIsiShown: true,
};

Isi.propTypes = {
	isFullIsiShown: PropTypes.bool,
};

export default Isi;
